.ant-space-item .yearPicker.status-btn-box {
border-radius: 0!important;

  }

  .ant-space-item .yearPicker.status-btn-box span {
    color: #fff;
    font-size: 12px !important;
    font-weight: 500;

  }
  .ant-space-item .yearPicker.status-btn-box span svg {
    color: #fff;
}
