.Button button {
  border-radius: 0px !important;
}
.select_dealer .ant-select-selector{
  border-radius: 0px;
}
.date {
  display: flex;
  border-radius: 0px !important;
  height: 42px !important;
}
.date .start {
  border-radius: 0px;
  height: 42px;

}
.date .start .ant-picker-input input{
  font-size: 13px !important;
}
.date .end .ant-picker-input input{
  font-size: 13px !important;
}
.date .end {
  border-radius: 0px;
  /* border-top-right-radius: 21px;
  border-bottom-right-radius: 21px; */
  height: 32px;
  font-size: 13px !important;

}
.ant-col {
  gap: 10px !important;
}
/* div#root .ant-row .ant-col-20 {
  margin: auto;
} */
.nameTagWrap .nameHead {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.nameTagWrap .nameTitle {
  color: #BCB38D;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.03em;
}
.button_inner_sec {
  display: flex;
  align-items: center;
  gap: 5px;
}
.button_inner_sec  Button{
  font-size: 13px !important;
  height: 42px !important;
}
.Button .upload_btn {
  width: 30px;
  background-color: #fce838;
}
.Button .upload_btn span svg {
  position: relative;
  /* right: 1px; */
}
.ant-btn
  .css-dev-only-do-not-override-10ed4xt
  .ant-btn-circle
  .ant-btn-default
  .ant-btn-icon-only
  span
  svg
  path {
  padding-right: 20px;
}

.dealerCode {
  display: flex;
  height: 41.02px;
}
.unconditional {
  font-weight: 700;
  font-size: 30px !important;
  line-height: 37px !important;
}
.Button .ant-col .ant-space-vertical .ant-space-item .ant-select.select_dealer .ant-select-selector {
  height: 42px !important;
}
.ant-picker .ant-picker-input input::placeholder {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  padding: 4px 7px;
  color: #464646;
}
.infoIcon {
  /* width: 30px; */
  height: 32px;
}
.infoCircleIcon svg {
  fill:red
}