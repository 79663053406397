.action_button {
  background: #464646;
  border-radius: 0px;
  height: 38px !important;
  width: 120px;

}
.action_button .ant-space-item {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
}
.action_button .anticon-down svg {
  color: #fff;
}
.button_Type{
  color:rgba(255, 255, 255, 0.5);
  font-size: 12px;
}
.demo .action_button {
  width: auto;
}
.download_button {
  color: #fff;
  background-color: #464646;
  border-radius: 0px;
  height: 35px !important;
  width: 45px;
  position: absolute;
  top: 12px;
  right: 400px;
}
.demo .action_button {
  width: auto;
  margin: 0 0.5rem 0rem;
}
.demo{
  text-align: right;
  /* display: none; */
}
.ant-row .schemi-btn-all, .ant-btn-lg {
  font-size: 16px !important;
  font-weight: 500!important;
  height: 50px !important;
  width: max(190px,13vw) !important;
  background: #236238 !important;
  color: #fff !important;
  margin-right: 0 !important;
}

.ant-btn-lg {
  border-radius: 0px !important;
  width: max(190px,13vw) !important;
  height: 50px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
  line-height: 20px !important;
  color: #FFFFFF;
  padding:0!important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content, .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content {
  text-transform: capitalize;
}
.ant-select .ant-select-arrow .anticon >svg {
  color: #D9D1B1;
}
.ant-btn.ant-btn-round.ant-btn-lg {
  border-radius: 0;
}

/* Status btn */
.ant-row .ant-space-item .ant-btn.yearPicker.status-btn-box {
  padding: 0px 0px !important;
  border-radius: 0 !important;
  font-size: 12px;
  width: 155px !important;
  font-weight: 600;
  text-transform: uppercase;
  background: #464646;
  height: 38px !important;
  text-transform: capitalize;
}

.ant-collapse .allSchmeStatus>div>div {
  float: left;
}
.ant-tag.ant-tag-has-color {
  color: #000000;
}
.ant-col .ant-btn-lg {
  border-radius: 0 !important;
}
thead.ant-table-thead tr:first-child th.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  /* background: #FFFDF4; */
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  /* color: #BCB38D; */
  text-align: left !important;
  font-weight: 600;
  right: 0px !important;
}
.table_sub_buttons .ant-btn svg {
  width: 25px;
  height: 25px;
}
.All_scheme_inner {
  float: right;
}
tbody.ant-table-tbody td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first .ant-space {
    /* display: block !important; */
}
tbody.ant-table-tbody td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first .ant-space .ant-space-item {
  margin: 10px 0;
}
.ant-modal .ant-modal-content .ant-modal-footer button {
  height: 53px !important;
}
.table_scheme_wrap .ant-row {
  padding: 0 !important;
  padding-top: 14px !important;
}
/* .ant-dropdown-placement-bottomLeft {
  left: 1834px !important;
  top: 313px !important;
} */
.all_schemeTable_pagination .ant-spin-nested-loading .ant-row {
  float: unset;
}
.collection-slab-based.action_button {
  width: 206px !important;
}
.collection-slab-based.action_button .ant-space-item {
  overflow: hidden;
  padding-bottom: 1px;
  padding-right: 4px;
}
.ant-table-column-sorters span.ant-table-column-sorter.ant-table-column-sorter-full {
  background: #fff;
  border-radius: 50%;
  padding: 3px 8px;
  position: absolute;
    right: 5px;
}
form.ant-form.ant-form-horizontal .ant-table-wrapper {
  padding: 0 !important;
}
.Button .ant-col .ant-space-vertical .ant-space-item .ant-select.select_dealer .ant-select-selector span.ant-select-selection-placeholder {
  color: #464646;
}
.ant-picker-input.ant-picker-input-active input::placeholder {
  font-size: 14px !important;
}
.ant-picker .ant-picker-input input::placeholder {
  font-size: 14px !important;
}
.ant-row .collection-schme-box .ant-row {
  /* justify-content: space-between !important; */
  align-items: center !important;
}
.ant-picker .ant-picker-input >input::placeholder {
  font-size: 15px !important;
}
