.head_Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    cursor: default;
}
.ant-row  .head_Wrapper {
    padding: 25px 0;
}
.head_title span {
    font-weight: 800;
    font-size: 30px;
    text-transform: uppercase;
    padding-left: 60px;
    color: #333333;
}
.ant-row .head_title span {
    font-size: 27px;
    padding-left: 0;
    font-weight: 700;
}
.head_right_sec {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-items: center;
}
/* .ant-space-item svg{
    width: 10px;
    height: 10px;
}  */
/* .editButton  svg {
    height: 50px !important;
    width: 50px !important;

} */
.ant-dropdown-trigger .ant-space .ant-space-item svg.downwards {
    position: relative;
    top: -6px;
    right: 5px;
}
