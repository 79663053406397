.cards-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.text-header-bottom {
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
  font-weight: 500;
  color: #545454 !important;
  text-transform: capitalize;
}

.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  margin: 0 auto;
  width: 14%;
  height: 140px;
  border: 1px solid;
  border-radius: 5px;
  /* box-shadow:  -5px -5px 30px 7px rgba(0,0,0,0.22); */
  cursor: pointer;
  transition: 0.4s;
  word-wrap: break-word;
}

.card .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card .card_image img {
  width: inherit;
  height: inherit;
  border-radius: 40px;
  object-fit: cover;
}

.card .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: -80px;
  height: 40px;
}

.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 1px 1px 7px 3px rgba(0, 0, 0, 0.25),
    -1px -1px 7px 3px rgba(0, 0, 0, 0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
    max-width: 100%;
  }
}

.status {
  margin-right: 10px;
}

/*
  .card {
    margin: 30px auto;
    width: 300px;
    height: 300px;
    border-radius: 40px;
    background-image: url('https://i.redd.it/b3esnz5ra34y.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
  box-shadow: 5px 5px 30px 7px rgba(0,0,0,0.25), -5px -5px 30px 7px rgba(0,0,0,0.22);
    transition: 0.4s;
  }
  */
.awatingul .card {
  width: 16%;
  float: left;
  margin-right: 5px;
}

.awatingul .cards-list {
  display: inline-block;
}