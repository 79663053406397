.nameTagWrap {
    display: flex;
    flex-direction: column;
}

.nameHead span {
    font-size: 16px;
    color: #464646;
    font-weight: 700;
    line-height: 20px;
}

.nameTitle span {
    font-size: 13px;
    color: #BCB38D;
    letter-spacing: -0.03em;
    line-height: 16px;
    font-weight: 500;
}