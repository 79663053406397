.select_state .ant-select-selector{
    border-radius: 0px !important;
    font-size: 16px;
    width: 170px !important;
    padding: 0px 10px 0px 10px;
    color: black;
}

.main-div-select{
    display: flex !important;
    padding: 20px 0px 20px 60px;
    gap: 10px;
}

.state-wise-filters {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center !important;
}

.state-wise-filters .state-wise-monthly-filters {
    padding-top: 16px;
}

.state-wise-filters .month{
    display: flex;
    border-radius: 58px !important;
    color: black;
    padding-left: 10px;
}
.state-wise-filters  .start{
    border-radius: 0px;
}
.state-wise-filters .end{
    border-radius: 0px;
}
thead.ant-table-thead {
    text-align-last: center;
  }
  .calendar_zonalHead{
    display: flex;
   padding-left:5%;
    background: #FFFFFF;
    }
  