.alertModal .ant-modal-footer  {
    text-align: center;
}
.alertModal button.ant-btn {
    background-color: #464646;
    color:#FFFFFF;
    width: 115px;
    height: 40px !important;
}

.alertModal .ant-modal-title {
    text-align: center;
    font-size: 30px;
  }

 /* .alertModal .ant-modal-content {
    border-radius: 20px;
    margin: auto;
    width: 374px;
    height: 165px; */
    /* width: 426px;
height: 332px; }*/
.alertModal.ant-modal .ant-modal-body {
    font-size: 14px;
    line-height: 1.5714285714285714;
    word-wrap: break-word;
   
}
.mailModal.ant-modal .ant-modal-content{
    width:100%;
    text-align: left;
}
.alertModal .ant-modal-content .ant-modal-body p.custom-warning {
    font-size: 30px;
    font-weight: 600;
    line-height: 37px;
    margin-bottom: 17px;
}
.error-list-p ul {
  list-style: auto;
  /* padding-right: 33px; */
  padding: 20px;
}
.custom-warning{
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 10px;
}
.custom-icon.ant-modal-body span.anticon svg {
    width: 70px;
    height: 70px;

}
.ant-pagination .ant-pagination-disabled button .anticon.anticon-left svg {
    color: #fff !important;
}

.ant-pagination .ant-pagination-prev  {
    background: #E4E4E4;
    border-radius: 50%;
    padding: 0 !important;
    margin: 0 6px !important;
    height: 35px !important;
    width: 35px;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev:active .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next:active .ant-pagination-item-link,.ant-pagination.ant-pagination-mini .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination.ant-pagination-mini .ant-pagination-next:hover .ant-pagination-item-link {
    background-color: transparent;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination .ant-pagination-next {
    background: #464646;
    border-radius: 58px;
    padding: 9px 10px;
    height: auto !important;
    margin: 0 0 0 6px !important;
    color: #fff;
}
.ant-pagination.ant-pagination-mini .ant-pagination-jump-next .ant-pagination-item-link .ant-pagination-item-ellipsis {
    font-weight: 500;
    font-size: 12px;
    line-height: 32px;
    text-transform: uppercase;
    color: #464646;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination .ant-pagination-options {
    display: none;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination .ant-pagination-next button svg {
    color: #fff;
}
.ant-tabs-content-holder .ant-tabs-content .ant-col .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}
.ant-pagination .ant-pagination-item a {
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    border-radius: 50%;
    font-weight: 500;
    font-size: 12px;
    padding: 1px 6px;
}
/* .anticon svg {
    display: inline-block;
    color: #464646;
} */
.ant-pagination .ant-pagination-item-active a {
    font-weight: 600;
    border-radius: 50px;
    background-color: #FCE838;
    border-color: #FCE838;
    font-weight: 700;
    font-size: 12px;
    color: #464646;
    /* line-height: 35px; */
}
.ant-row .collection-schme-box .ant-pagination .ant-pagination-disabled button .anticon.anticon-left svg {
    color: unset;
}
.ant-row .collection-schme-box .ant-space .ant-space-item button {
    /* color: #fff !important; */
    border-radius: 0 !important;
}
.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 10px !important;
    align-items: center;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev, .ant-pagination.ant-pagination-mini .ant-pagination-next{
    height: 35px;
}
.ant-spin-nested-loading .ant-spin-container .ant-table-body {
    background: #fff;
}
.ant-spin-nested-loading .ant-spin-container .ant-table-body  td.ant-table-cell.ant-table-column-sort.ant-table-cell-fix-left {
    background: #fff;
}

.logo_sec button svg {
    background: #2a7342;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transform: rotate(181deg);
    border: 1px solid #2a7342;
}
.logo_sec button svg path{
    fill:#ebf4ee;
}
/* .logo_sec button svg {
    margin-left: 12rem;
} */
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed .logo_sec button svg {
    margin-left: 3rem !important;
    transform: rotate(-178deg);
}
.ant-spin-nested-loading .ant-spin-container .ant-table-body td.ant-table-cell.ant-table-column-sort.ant-table-cell-fix-left {
    word-break: break-all;
}
.ant-collapse .ant-collapse-content>.ant-collapse-content-box .ant-table-wrapper .ant-table .ant-table-container table>thead>tr:first-child th:first-child {
    text-align: left !important;
    border-radius: 0;

}
.ant-spin-nested-loading {
    padding-top: 25px;
}

