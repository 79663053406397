.create_scheme_wrap{
    padding: 0 60px 0 60px !important; 
}
 .approval_heading{
    padding: 15px 0px !important;
    float: left;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
}

.approval_heading1{
    padding: 0 0 15px 0!important;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
}
.approval-scheme .ant-collapse .ant-collapse-item {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}
.collapse-items.approval-scheme {
    padding-top: 15px;
    clear: both;
}
.ant-space-item .ant-collapse>.ant-collapse-item {
    padding: 7px 21px !important;
}

.ant-row.finance-edit-col .ant-row.button_inner_sec {
    padding: 0 !important;
}
.ant-row.finance-edit-col .ant-col {
    flex-direction: row-reverse;
}
.onemoth-date-selector .ant-row {
    padding: 0 !important;
}
thead.ant-table-thead {
    text-align-last: center;
}
.approval-count {
    font-size: 20px;
}
.editButton  svg {
    height: 30px !important;
    width: 30px !important;
  
}
.disabled {
    pointer-events: none;
}
.enabled {
    pointer-events: auto;
}