body{
    font-family: 'Montserrat' !important;
}
/* .css-ag7rrr-MuiTypography-root{
    font-family: 'Montserrat' !important;
} */
.css-j204z7-MuiFormControlLabel-root {  
    margin-left: 0px !important;
}   
.css-101ca9i-MuiTypography-root-MuiLink-root {
   margin-left: 159px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:invalid {

    padding-left: 38px;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:invalid {
    padding-left: 38px;
}
.remember{
    color:#464646!important;
}
.login{
    background-color: #FCE838 !important;
    color:#333333 !important;
    width: 215px !important;
    font-size: 18px !important;
    border-radius:0  !important;
    align-items: center !important;
    font-weight: 700 !important;
    height: 51px;
    
    
}
.sw-login-wrapper .login-text-field > *, 
.sw-login-wrapper .login-text-field1 > *{
    border-radius: 0 !important;
    width: 100%;
   
}
.remember-wrap .forget-pass {
    text-align: right;
}
.remember-wrap .forget-pass a {
    color: #BCB38D !important;
    text-decoration: underline !important;
    text-decoration-color: #BCB38D !important;
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0 !important;
    font-weight: 500;
}
.MuiBox-root .css-1y8ugea .heading{
    font-weight: 700 !important;
    font-size: 40px !important; 
    line-height: 42px !important;
    text-align: center !important;
    color: #464646 !important;
    margin-bottom: 16px !important;
     text-transform: none !important;

}
.MuiBox-root .css-1y8ugea .css-ahj2mt-MuiTypography-root{
    font-weight: 500;
font-size: 16px !important;
line-height: 20px !important;
text-align: center;
color: #464646;
}

.MuiBox-root.css-1y8ugea p{
    padding-top: 16px;
    font-weight: 500;
}
.MuiBox-root.a.MuiTypography-root.MuiTypography-body2.MuiLink-root .css-101ca9i-MuiTypography-root-MuiLink-root{ 
font-weight: 500;
font-size: 16px;
line-height: 20px;
display: flex;
align-items: center;
text-align: center;
text-decoration-line: underline;
color: #BCB38D;
}


/* .h1{
    
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important; 
    line-height: 42px !important;
    text-align: center !important;
    color: #464646 !important;
    margin-bottom: 16px !important;
} */
.h2{
    
    font-style: normal !important;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 20px !important ;
    text-align: center !important  ;
    color: #464646 !important;

}
.login-btn{
    text-align: center;
}
.remember {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.forgot-pwd {
    width: auto !important;
}
.home{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.box {
    margin: 0;

}
.box .login-text-field {
    margin: 58px 0 15px;
}
.box .login-text-field1 {
    margin: 0px 0 16px;
}
.remember span {
    padding: 0 5px 0 0;
    font-weight: 500 !important;
    color: #464646;
}
.login-btn button {
    margin-top: 67px;
    font-size: 16px!important;
}
.MuiBox-root .css-ahj2mt-MuiTypography-root {
    font-weight: 700;
}
.css-101ca9i-MuiTypography-root-MuiLink-root {
   
    font-family: 'Montserrat' !important;
    color: #BCB38D !important;
    text-decoration: underline !important;
    text-decoration-color: #BCB38D !important;
    font-size: 16px !important;
line-height: 20px !important;


}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium.css-1laqsz7-MuiInputAdornment-root {
    margin-right: 21px;
}
form.box.MuiBox-root.css-164r41r {
    display: flex;
    flex-direction: column;
}

.css-1y8ugea h1 {
    text-transform: capitalize;
}
.css-gadse4-MuiPaper-root-MuiGrid-root .css-1y8ugea {
    margin-top: 6.8rem;
}

input#Username::placeholder{font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #464646;
}
/* .MuiGrid-container .css-gadse4-MuiPaper-root-MuiGrid-root form.box .MuiInputBase-root {
    width: 100%;
    border-radius: 0;
} */
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input::placeholder, .MuiGrid-container .css-gadse4-MuiPaper-root-MuiGrid-root form.box .MuiInputBase-root input#password::placeholder{
    color: #464646!important;
    font-weight: 500 !important;
    opacity: 1 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    height: 30px;
}
.MuiBox-root input {
    padding-left: 38px;
    height: 2em;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill, .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    padding-left: 38px;
}

@media only screen and (max-width: 1024px) {
    .main.MuiGrid-root.MuiGrid-container.css-1jrn2fe-MuiGrid-root {
        /* display: flex; */
        flex-wrap: wrap;
        flex-direction: column !important;
    }
    .css-gadse4-MuiPaper-root-MuiGrid-root {
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
    .css-18mbdf2-MuiGrid-root {
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
    .css-gadse4-MuiPaper-root-MuiGrid-root {
        max-height: 66.666667% !important;
    }
    .css-18mbdf2-MuiGrid-root {
        max-height: 33.333333% !important;
    }

    .css-gadse4-MuiPaper-root-MuiGrid-root {
        max-height: 100% !important;
    }
    a.MuiTypography-root.MuiTypography-body2.MuiLink-root.MuiLink-underlineAlways.css-101ca9i-MuiTypography-root-MuiLink-root {
        margin-left: 0 !important;
    }
    .login-btn button {
        margin-top: 48px;
    }
    .css-gadse4-MuiPaper-root-MuiGrid-root {
        flex-basis: 100%;
    }
    .css-18mbdf2-MuiGrid-root {
        max-height: 100% !important;
        height: 100%;
    }
    .css-101ca9i-MuiTypography-root-MuiLink-root {
        white-space: nowrap;
    }
    .remember span {
        white-space: nowrap;
    }
    /* .MuiGrid-root.MuiGrid-container {
        flex-wrap: unset;
    } */
}

@media(min-width:1025px) and (max-width:1266px){
    .css-1y8ugea form.box.MuiBox-root.css-164r41r .css-11lq3yg-MuiGrid-root .MuiGrid-root a {
        margin-left: 0 !important;
    }
}
.welcomingtext{
    color: #464646;
}