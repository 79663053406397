.create_scheme_wrap{
  padding: 0 60px 0 60px;
}

.all_Scheme button {
  border-radius: 58px !important;
  font-size: 16px;
  width: 170px !important;
  padding: 0px 10px 0px 10px;
  color: black;
}

.all_Scheme_header .month{
  display: flex;
  border-radius: 58px !important;
  height: 42px;
  color: black;
}
.all_Scheme_header .start{
  border-radius: 0px;
  height: 42px !important


}

.all_Scheme_header .end{
  border-radius: 0px;
  height: 42px !important


}
.all_Scheme_header .Enforce_month{
  font-size: 5px;
  height: 32px;

}
.All_Scheme_pagination{
  text-align: center;
  position: sticky;
  bottom: 10px;
  left: 60% ;
  padding-bottom: 50px;
}



.panel_heading .ant-collapse-header-text{
  font-size: 18px;
  font-weight: 600;
  color: #464646 ;
}
.panel_heading .ant-collapse-expand-icon .anticon svg{
  color: #C7C7C7;
  width: 17px;
  height: 17px;

}
.month input , .all_Scheme .ant-space-item input {
  font-size: 10px !important;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
}
.collapse-items{
  display: flex;
}
.ant-collapse {
  border: 0px solid transparent;
}
.all_Scheme .ant-space-item {
  font-size: 13px !important;
}
.collection_header .All_scheme_inner .ant-space-item button.ant-btn .ant-space {
  color: #464645;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 15px 0px 12px;
}
 .ant-collapse-header {
  border-radius: 8px 8px 0 0;
}
.ant-collapse-header {
  border-radius: 8px 8px 0 0 !important;
}

.month input::placeholder, .all_Scheme_header .ant-space-item input::placeholder {
  color: #000;
}
.all_scheme_searchin {
  border-radius: 100px !important;
  display: flex;
  align-items: center;
}
.all_scheme_searchin .search_background {
  right: 0;
  left: unset;
  position: absolute;
  top: 0;
  padding: 10px;
}


.collection_header{
  display:flex;
  align-items:center !important;
  justify-content:space-between !important;
  font-weight:700 !important;
  padding: 0 0 40px !important;
}

.create_scheme_wrap .create:hover {
  color: #464646;
  background-color: #F7F7F7;
  /* border-radius: 50px !important; */
  padding: 8px 8px !important ;
}
.collection_header .schemes {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
}
.ant-row .collection-schme-box {
  background: transparent;
  margin: 0;
  padding: 0 !important;
}

.ant-row .ant-space-item .ant-btn {
  /* padding: 8px 20px !important; */
  border-radius: unset !important;
  color: unset !important;
}
 .ant-collapse-item.panel_heading {
  border-bottom: 0px solid transparent;
}

.ant-row .ant-space-item .ant-btn .ant-space-item {
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
}
.month input, .all_Scheme_header .ant-space-item input {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 16px;
}


/* status css */
.All_scheme_status{
  display:flex;
  justify-content:flex-start;
  margin-bottom:20px;
  justify-content:flex-end;
  gap: 20px;
  margin-right: 0 !important;
  float: left;
}
.Schemes_status{
  display: flex;
   align-items: center;
   gap: 5px;
}
.instalttion-collapse-items {
  padding-top: 10px;
  clear: both;
}
.instalttion-collapse-items .ant-collapse-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 8px;
}
.instalttion-collapse-items  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
  align-items: center;
}
.Status_color{
  width: 5px;
  height: 13px;
  font-weight: 600;
font-size: 14px;
border-radius: 50%;
}
.All_scheme_status .Schemes_status:first-child .Status_color {
  background: #51DE4E !important;
}
/* pagination  */
.All_Scheme_pagination .ant-pagination{
  justify-content: center;
}

.Search_button{
  color: yellow !important;
}
.All_Scheme_pagination  .ant-pagination-item-active {
  font-weight: 600;
  border-radius: 50px;
  background-color: #FCE838 ;
  border-color: #FCE838;
}
.All_Scheme_pagination .ant-pagination-item:hover{
  font-weight: 600;
  border-radius: 50px;
  color: #464646;
  background-color: #FAFAFA;
  border-color: #D8D8D8;
}

.All_Scheme_pagination .ant-pagination-item-active:hover a {
  color: #464646;
  background-color: #FAFAFA;
  border: 1px solid;
  border-radius: 50px;
  font-weight: 600;
  border-color: #D8D8D8 !important;
}
.All_Scheme_pagination .ant-pagination-item-active a {
  color: white;

}
.allscheme_btn_newscheme {
  float: right;
}
.ant-modal .ant-modal-content .ant-modal-footer button.ant-btn:first-child {
  background-color: #464646;
  border: 0;
  border-radius: 0;
  color: #c7c7c7;
  font-weight: 400;
  /* height: 53px!important; */
  margin: 15px 0;
  text-transform: uppercase;
  width: 140px;
}
.ant-modal .ant-modal-content .ant-modal-footer button.ant-btn:first-child span {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
}

/* overriding pagination classes for default table pagination  */

.all_schemeTable_pagination .ant-pagination-item-active a {
  font-weight: 600;
  border-radius: 50px;
  background-color: #FCE838 ;
  border-color: #FCE838;
  color: white;

}
.all_schemeTable_pagination .ant-pagination-item a:hover {
  font-weight: 600 !important;
  border-radius: 50px !important;
  color: #464646 !important;
  background-color: #FAFAFA  !important;
  border: 1px solid #D8D8D8  !important;
}
.all_schemeTable_pagination .ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border-color: white !important;
}
.ant-space .ant-collapse-extra {
  height: 45px;
  width: 45px;
  margin-bottom: 5px;
  border-radius: 50%;
  background: #F7F7F7;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  text-align: left;
}
td.ant-table-cell {
  text-align: center !important;
}
.ant-table-column-sorters span.ant-table-column-title {
  text-align: center !important;
}
.dashboard-collapse .zonalhead .ant-row {
  align-items: center;
  justify-content: space-between !important;
}
thead.ant-table-thead {
  text-align-last: center;
}
