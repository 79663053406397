/* .test {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
} */
.custom-tabs .ant-tabs-ink-bar {
  background: #fce838;
}

.custom-tabs .ant-tabs-tab-btn {
  color: #464646 !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.ant-modal-body .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: space-between;
}

.custom-tabs .ant-tabs-tabpane {
  text-align: center;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  text-align: left;
}

.SummaryModal .ant-modal-footer button.ant-btn span {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
}

.ant-checkbox-group {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

.test .ant-modal-footer {
  text-align: center;
}

.test button.ant-btn {
  background-color: #464646;
  color: #ffffff;
  border-radius: 20px;
}

button.ant-btn.ant-btn-primary {
  background-color: #fce838;
  /* color: black; */
  font-weight: 700;
  border-radius: 0;
  /* height: 53px !important; */
  margin: 12px 0px;
  text-transform: uppercase;
  padding: 4px 15px;
  width: 140px;
}

.ant-modal .ant-modal-content .ant-modal-footer button.ant-btn.ant-btn-primary {
  height: 53px !important;
  border: 1px solid transparent
}

.ant-modal .ant-modal-content .ant-modal-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.logged-out-btn {
  padding: 15px 42px;
  height: auto;
  position: relative;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected {
  background: transparent !important;
}

.ant-menu-submenu-open.ant-menu-submenu-active,
.ant-menu-submenu-open.ant-menu-submenu-selected ul {
  /* background: #555 !important; */
}

.logged-out-btn::before {
  content: "\A";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #fff transparent transparent;
  position: absolute;
  right: 5px;
  top: -16px;
  transform: rotate(90deg);
}

.ant-space .ant-space-item button {
  color: #fff !important;
  border-radius: 10px !important;
}

.create_scheme_wrap .ant-space .ant-space-item button {
  color: #000 !important;
}

.ant-space .ant-space-item .logged-out-btn {
  color: #000000 !important;
}

label.ant-checkbox-wrapper.ant-checkbox-group-item {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
}

.ant-btn-primary.logged-out-btn {
  background: #fff !important;
  position: absolute;
  right: -11px;
  top: 0;
  font-weight: 500 !important;
  height: 53px !important;
}

.ant-table-wrapper .ant-table.ant-table-small .ant-table-body {
  overflow-y: unset !important;
}

.ant-row.summary_card .ant-picker {
  width: 100%;
  background: #F7F7F7 !important;
  /* border-radius: 58px !important; */
  color: #464646 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  border-radius: 0;
  border: 0;
  width: 100%;
  height: 53px !important;
}

.checkbox-group {
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  padding: 5px 0px;
}