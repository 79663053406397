.create_scheme_wrap{
    padding: 0 60px 0 60px;
  }

  .create_scheme_wrap .draft-scheme-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
  }
  .create_scheme_wrap .draft-scheme-header .draft-scheme-header-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
  }

  .create_scheme_wrap .create-btn {
    background-color: yellow;
  }

  .draft-scheme-pagination {
    text-align: center;
    padding: 8px 0px;
  }
  thead.ant-table-thead {
    text-align-last: center;
  }
.all_scheme_draft{
/* gap: 24px !important;
display: flex;
flex-direction: column  ; */
}


