.alertBox{
    width: 220px;
}
.innerAlert{
    /* height: 30px !important; */
    line-height: 13px !important;
    border-radius: 18px !important;
    font-size: 11px !important;
    color: #FF3232 !important;
    padding: 6px 15px 6px 15px !important;
    border: none !important;
    flex-direction: row-reverse;
    gap: 10px;
    font-weight: 600;

}
.AlertIcon_position{
    position: relative;
}