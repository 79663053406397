* {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-family: 'Montserrat' !important;
}


.heading {
  font-size: 40px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header,
.ant-collapse.all_scheme_draft .ant-collapse-item {
  border-radius: 8px;
  padding: 0px 0px !important;
}

.ant-collapse.all_scheme_draft>.ant-collapse-item>.ant-collapse-header {
  align-items: center;
}

.ant-table-wrapper .ant-table .ant-table-title+.ant-table-container table>thead>tr:first-child th:first-child {
  text-align: center !important;
}

.ant-btn {
  height: auto !important;
}

.ant-space {
  display: flex !important;
  justify-content: space-between;
  justify-content: center;
  justify-content: end;
}

.instalttion-collapse-items .ant-collapse>.ant-collapse-item {
  padding: 15px 16px !important;
}

.ant-table-body::-webkit-scrollbar {
  /* width: 25px !important; */
  height: 15px !important
    /* width of the entire scrollbar */
}

.custom-message {
  margin-top: 100px;
  font-size: 20px;

}

.pt-3 {
  padding-top: 1.5rem;
}

.p-5 {
  padding: 3rem !important;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.px-3 {
  padding-top: 3%;
  padding-bottom: 3%;
}

.px-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.px-1 {
  padding-top: 1%;
  padding-bottom: 1%;
}

.py-1 {
  padding-left: 1%;
  padding-right: 1%;
}

.py-2 {
  padding-left: 2%;
  padding-right: 2%;
}

.bg-danger {
  background-color: rgb(250, 194, 194);
}

.input1 {
  padding: 3% !important;
  border: 1px solid #CBDFF9;
  border-radius: 2px
}

.input1:focus {
  outline: #2F82EF !important;
}

.input-disabled {
  padding: 3% !important;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: lightgrey;
}

.input-disabled1 {
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: lightgrey;
}

.input1-disabled:focus {
  outline: #2F82EF !important;
}

li.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-item-icon {
  width: 20px;
  height: 20px;
}

.loader-show {
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0;
  opacity: 0.5;
  z-index: 99999999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease all .3s;
}

.loading-16 {
  width: 50px;
  height: 50px;
  margin: 20px auto;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #11301C;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#space .ant-btn {
  border-radius: 0 !important;
  height: 42px !important;
  width: 90%;
}

#table.news #space .month {
  margin-right: 10px;
}

#table.news #space .month .ant-picker.ant-picker-range {
  border-radius: 0 !important;
}


#table.news #space .allSchmeStatus.select_state.heightauto {
  width: 16%;
}

.positionicons {
  position: absolute;
  right: 0;
}

#table.news #space .ant-col,
#table.news #space .ant-select.select_state,
#table.news #space .marginauto {
  width: 18%;
  text-align: left !important;
}

#table.news #space .ant-space {
  width: 100%;
}

#table.news #space .ant-space .ant-space-item {
  display: inline-block;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}



#table.news #space .ant-select.select_state .ant-select-selector {
  width: 100% !important;
}

#table.news #space .marginauto {
  margin-right: 0;
}

#table.news #space .marginauto .ant-picker {
  width: 90%;
}

#table.news #space .month {
  float: left;
}

#table.news {
  margin-top: 0;
}

#table.news div#space {
  position: relative;
}

#table.news .ant-table-container .ant-table-body {
  max-height: 400px !important;
}

#table.news .ant-pagination {
  margin-bottom: 0;
}

#table.news .ant-spin-nested-loading {
  padding: 0;
}

.calendar_zonalHead .ant-select-selector {
  height: 45px !important;
  line-height: 45px !important;
  padding: 6px 10px !important;
}

.calendar_zonalHead .ant-select {
  padding: 0px 0 !important;
  width: 170px;
}

.calendar_zonalHead {
  width: 170px;
}

#table.news #space .ant-select.select_state.heightauto {
  margin-right: 12px;
}

#table.news #space .ant-select.select_state.heightauto .ant-input {
  height: 42px;
  padding: 4px 15px;
  font-size: 13px !important;
  color: #000;
  font-weight: 400;
}

.positionicons {
  position: absolute;
  right: 20px;
  top: 5px;
}

.text-center {
  text-align: center;
}

button.ant-btn.ant-btn-primary {
  background-color: #379355 !important;
  color: "white !important";
}

.c-black1 {
  color: #545454 !important;
}

.d-flex {
  display: flex
}

.fs-10 {
  font-size: 10px;
}


.f-reverse {
  flex-direction: row-reverse
}

.actionbuttons button {
  background: #236138;
  color: #fff !important;
}

.actionbuttons.rightmargin {
  margin-left: 21px !important;
}


@media only screen and (min-width:1000px) and (max-width:1200px) {
  #table.news .ant-table-container .ant-table-body {
    max-height: 380px !important;
  }
}

.w-50 {
  width: 50% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2a7342;
}

.flex-wrap {
  flex-wrap: wrap
}

.vh-100 {
  height: 100vh;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #53c278;
  /* Border color */
  color: #1f1f1f;
  /* Text color */
  border-radius: 5px;
  /* Rounded corners */
  transition: background-color 0.3s ease;
}

.my-1 {
  margin: 0 1% 0 1%;
}

.my-3 {
  margin: 0 3% 0 3%;
}

.m-3 {
  margin: 3% 3%;
}

.m-5 {
  margin: 3rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.h-45 {
  height: 45px !important;
}

.bg-red {
  background-color: red;
}

ul {
  list-style-type: none;
}

.bg-white {
  background-color: white;
}

.py-3 {
  padding: 0 3% 0 3%;
}

.p-2 {
  padding: 0.5rem 0.5rem;
}

.p-1 {
  padding: 1% 1%;
}

.fs-2 {
  font-size: 1.8rem !important;
}

.All_scheme_status1 {
  display: flex;
  margin-bottom: 5px;
  gap: 25px;
  padding-top: 2%;
  float: left;
  justify-content: flex-start !important;
}

.d-none {
  display: none;
}

.allSchmeStatus2 {
  display: flex;

}

.flex-reverse {
  flex-direction: row-reverse
}

.paginationDesign {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}

.c-white {
  color: white;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.onemoth-date-selector1 {
  padding: 5px 5px !important;
  background: #fff;
  margin-top: 1.5rem;

}

.h-2 {
  height: 2.5rem !important;
}

.custom-file-input {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-file-input input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-file-input label {
  display: inline-block;
  padding: 5px 10px;
  width: 100%;
  background-color: #236138;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.custom-file-input label:hover {
  background-color: #2980b9;
}

.justify-content-between {
  justify-content: space-between;
}

.h-full {
  height: 100% !important;
}

.h-30 {
  height: 30px;
}

.m-100p {
  width: 100px;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.active-row td {
  background-color: #ffc5c5 !important;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  min-width: 130px
}

.ant-select-selector {
  min-width: 130px
}


.stateWise:where(.css-dev-only-do-not-override-18iikkb).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  min-width: 220px
}

.stateWise.ant-select-selector {
  min-width: 220px
}

.h-24 {
  height: 24px;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper .ant-table-cell,
:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper .ant-table-tbody>tr>td,
:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper tfoot>tr>th,
:where(.css-dev-only-do-not-override-18iikkb).ant-table-wrapper tfoot>tr>td {
  /* overflow-wrap: unset !important */
}

.m-h400 {
  max-height: 400px;
}

.overflow-auto {
  overflow: auto;
}

.text-danger {
  color: red;
  padding-top: 20px;
  font-weight: 800;
}

.text-danger1 {
  color: red;
  font-weight: 800;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-2 {
  margin-left: 1rem !important
}

.pl-2 {
  margin-left: 1rem;
}

.align-items-center {
  align-items: center;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.h-42 {
  height: 42px;
}

.p-relative {
  position: relative !important;
}

.ant-switch.ant-switch-checked {
  background-color: #236138;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background-color: #236138;
}

.w-270 {
  width: 270px !important;
}


td.ant-table-cell {
  border: 1px solid #d8d8d881;
  /* padding: 5px 0 !important;
    text-align: left; */
}

.ant-table-wrapper table thead.ant-table-thead tr th {
  /* border: 1px solid #D8D8D8; */
}

/* .holdTable .ant-table-body table {
  min-height: 600px !important;
} */

.holdTable .ant-table-body {
  height: 600px !important;
  max-height: 600px !important;
}

.mr-2 {
  margin-right: 20px;
}

.flex-end {
  justify-content: flex-end
}

.pmis-active-row {
  background-color: #E4E4E4 !important;
}

.calendar_zonalHeadOneMonth {
  display: flex;
  background: #FFFFFF;
  height: 100%;
}

.calendar_zonalHeadOneMonth .ant-select-selector {
  height: 100% !important;
  line-height: 100% !important;
  padding: 6px 10px !important;
}

.calendar_zonalHeadOneMonth .ant-select-selection-search-input {
  height: 100%;
}

.calendar_zonalHead .ant-select-selection-search-input {
  height: 100%;
}

.perTr-table {
  background-color: #dadada !important;
}

.finance-table {
  background-color: #FEECE2 !important;
}

.nsh-table {
  background-color: #dadada !important;
}

.state-wise-select {
  border-radius: 0px !important;
  font-size: 16px;
  /* width: 170px !important; */
  /* height: 42px !important; */
  /* padding: 0px 10px 0px 10px; */
  /* color: black; */
  margin-left: 20px;
}

.state-wise-select div {
  height: 100% !important;
}

.state-wise-select .ant-select-selector {
  border-radius: 0px !important;
}

.ffecd2 {
  background-color: #ffecd2;
  font-weight: 700;
}

.ffecd2:hover {
  background-color: #ffd8a8;
  /* Change to a slightly darker or different shade */
  cursor: pointer;
  /* Optional: Change the cursor to a pointer to indicate interactivity */
}

.ff9696 {
  background-color: #ff9696;
  font-weight: 700;
}

.ff9696:hover {
  background-color: #ff7a7a;
  /* Change to a slightly darker or different shade */
  cursor: pointer;
  /* Optional: Change the cursor to a pointer to indicate interactivity */
}

/* Remove hover effect globally */
:where(.ant-table-tbody > tr):hover>td {
  background-color: inherit !important;
  /* Keeps original background */
  transition: none !important;
  /* Removes hover animation */
}

/* If using rowClassName, target specific tables */
.no-hover:hover td {
  background-color: inherit !important;
  transition: none !important;
}

.low-value {
  background-color: #ffecd2;
}

.high-value {
  background-color: #ff9696;
}

.low-value:hover {
  background-color: #ffecd2 !important;
}

.high-value:hover {
  background-color: #ff9696 !important;
  /* Light orange hover effect */
}

.ant-table-tbody>tr:hover>td {
  background: inherit !important;
}

/* If you're using row background colors */
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
  background: inherit !important;
}

/* Override the default hover behavior */
.custom-table .ant-table-tbody>tr:hover>td {
  background-color: inherit !important;
  /* Use your custom row color here */
}

/* If you want to remove the hover effect entirely */
.custom-table .ant-table-tbody>tr:hover {
  background-color: inherit !important;
}

.text-white {
  color: white !important;
  background: #464646;
  border-radius: 0px;
  height: 38px !important;
  width: 120px;
}