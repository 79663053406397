.create_scheme_wrap{
    padding: 0 60px 0 60px;
}
/* .allScheme{
    margin-bottom: 10px;
} */
.all_Scheme button {
    border-radius: 58px !important;
    font-size: 16px;
    width: 170px !important;
    padding: 0px 10px 0px 10px;
    color: black;
}
/* .all_Scheme{
    gap: 18px;
} */
.all_Scheme_header .month{
    display: flex;
    border-radius: 58px !important;
    height: 42px;
    color: black;
}
.all_Scheme_header .start{
    border-radius: 0px;
    /* border-top-left-radius: 21px;
    border-bottom-left-radius: 21px; */
    height: 35px !important;


}

.all_Scheme_header .end{
    border-radius: 0px;
    /* border-top-right-radius: 21px;
    border-bottom-right-radius: 21px; */
    height: 35px !important;


}
.all_Scheme_header .Enforce_month{
    font-size: 5px;
    height: 35px !important;

}
 .All_Scheme_pagination{
    text-align: center;
    position: sticky;
    bottom: 10px;
    left: 60% ;
    padding-bottom: 50px;
    /* transform: translateX(-60%); */
 }
 /* collapse */


.ant-collapse-header-text{
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #464646;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    padding-inline-end: 23px;
}
 .ant-collapse-expand-icon .anticon svg{
    color: #C7C7C7;
    width: 17px;
    height: 17px;

}
 .all_Scheme_header .all_scheme_search{
    border-radius: 100px !important;
    width: 140px !important;
    height: 35px !important;
    display: flex;
    align-items: center;

}
.all_Scheme_header .search_background svg {
    width: 16px;
    height: 16px;
}
.ant-picker .ant-picker-suffix .anticon.anticon-calendar svg {
    color: #D8D8D8;
    width: 18px;
    height: 18px;
}
.ant-picker .ant-picker-input {
    gap: 23px !important;
}
.search_background{
    background-color: #FCE838;
    padding: 9px;
    border-radius: 50px;
    position: relative;
    left: 11px;
    cursor: pointer;
}
.month input , .all_Scheme_header .ant-space-item input {
    font-size: 13px !important;
}
.collapse-items{
    display: flex;
}
.ant-collapse {
    display: flex;
    gap: 24px;
    flex-direction: column;
    background: transparent;
}
.ant-collapse .ant-collapse-item {
    padding: 15px 16px !important;
    align-items: center;
    background: #fff;
}

.all_Scheme_header .ant-space-item {
    font-size: 13px !important;
}


.month input::placeholder, .all_Scheme_header .ant-space-item input::placeholder {
    color: #000;
}

.more_option svg, .anticon-more svg{
    width: 30px !important;
    height: 30px !important;
    color: #464646;
    /* background-color: #F7F7F7; */
    border-radius: 50px !important;
    /* padding: 8px 8px !important ; */
}

 .ant-collapse-header{
    padding: 15px 16px !important;
    align-items: center;
    background: #fff;
}


/* status css */

/* pagination  */
.All_Scheme_pagination .ant-pagination{
    justify-content: center;
}

.Search_button{
    color: yellow !important;
}
.All_Scheme_pagination  .ant-pagination-item-active {
    font-weight: 600;
    border-radius: 50px;
    background-color: #FCE838 ;
    border-color: #FCE838;
}
.All_Scheme_pagination .ant-pagination-item:hover{
    font-weight: 600;
    border-radius: 50px;
    color: #464646;
    background-color: #FAFAFA;
    border-color: #D8D8D8;
}

.All_Scheme_pagination .ant-pagination-item-active:hover a {
    color: #464646;
    background-color: #FAFAFA;
    border: 1px solid;
    border-radius: 50px;
    font-weight: 600;
    border-color: #D8D8D8 !important;
}
.All_Scheme_pagination .ant-pagination-item-active a {
    color: white;

}


/* overriding pagination classes for default table pagination  */

.all_schemeTable_pagination .ant-pagination-item-active a {
    font-weight: 600;
    border-radius: 50px;
    background-color: #FCE838 ;
    border-color: #FCE838;
    color: white;

}
.all_schemeTable_pagination .ant-pagination-item a:hover {
    font-weight: 600 !important;
    border-radius: 50px !important;
    color: #464646 !important;
    background-color: #FAFAFA  !important;
    border: 1px solid #D8D8D8  !important;
}
.all_schemeTable_pagination .ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active) {
    border-color: white !important;
}

.table_sub_buttons {
    color: #464646 !important;
    border: 1px solid #D8D8D8;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid transparent;
    background: #fff;
}
.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}
thead.ant-table-thead {
    text-align-last: center;
  }
/* .all_schemeTable_pagination tr:nth-child(2n) td {
    background-color: #fbfbfb;
}
.all_schemeTable_pagination thead {
    background-color: #f1f1f1;
} */



