.nav_wrap {
  background: #464646;
  position: fixed;
  width: 250px;
  height: 100%;
  display: block;
  z-index: 999;
  padding: 0 23px;
}

.logo_sec {
  /* display: flex; */
  align-items: center;
  flex-direction: column;
  padding-top: 42px;
  margin-left: 12px;
}

.logo_title {
  /* padding-top: 50px; */
  text-align: center;
  color: #ffffff;
  width: 170px;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 30px;
  padding-top: 2px;
}

.logo_sec img {
  width: 170px;
}

.logo_sec span {
  text-align: left;
  color: #ffffff !important;
  text-transform: uppercase !important;
  display: block;
  white-space: nowrap;
  font-size: 10px;

}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed span.anticon.anticon-left-circle {
  width: auto !important;
  margin-left: 57px !important;
  transform: rotate(180deg);

}

aside.ant-layout-sider.ant-layout-sider-dark .ant-layout-sider-children .logo_sec button .anticon.anticon-right-circle {
  transform: rotate(180deg);
  margin-left: 42px;
  text-align: left;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #d9d9d9;
}

.ant-checkbox-checked:after {
  border: 2px solid #fce838;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: #fce838;
}


.ant-menu-title-content:hover {
  /* color:white !important; */
  color: #020203 !important;

}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  color: #c7c7c7 !important;
  font-size: 14px !important;
  line-height: 17px;
  font-weight: 400;
}

.ant-menu li svg path {
  fill: #fff !important;
  transition: 0.5s;
}

.nav_wrap ul li:hover svg path {
  fill: #fff !important;
  transition: 0.5s;
}

.nav_wrap ul li:active svg path {
  fill: #fce838 !important;
}

.ant-menu {
  font-size: 16px !important;
}

.ant-menu-light .ant-menu-item-selected {
  color: #ffffff;
}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
  color: #ffffff;
}

.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu) {
  color: #ffffff;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #555555 !important;
  color: #ffffff;
}

.ant-menu-submenu-title:hover {
  color: #fff !important;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #236138 !important;
  border-radius: 5px;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  background: transparent;
}

.ant-menu-submenu-open.ant-menu-submenu-active,
.ant-menu-submenu-open.ant-menu-submenu-selected {
  background-color: #236138 !important;
}

.ant-menu .ant-menu-submenu.ant-menu-submenu-inline {
  border-radius: 5px;
  margin: 20px 0;
}

.ant-menu .ant-menu-submenu.ant-menu-submenu-inline i.ant-menu-submenu-arrow::after,
i.ant-menu-submenu-arrow::before {
  color: #fff;
}

.nav_wrap ul.ant-menu.ant-menu-root {
  border-inline-end: 0;
}

.ant-menu .ant-menu-item-selected svg path,
.ant-menu .ant-menu-submenu-selected svg path {
  fill: #fff !important;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: #555555 !important;
}



.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: #236138 !important;
}

.ant-divider-horizontal {
  margin: 0;
  margin-bottom: 20px;
}




@media(max-width:1024px) {
  .ant-row .collection-schme-box {
    padding: 15px !important;
  }

  /* .button_inner_sec {
  padding: 0 15px;
} */
  .unconditional {
    padding: 0 15px 9px;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header>.ant-collapse-header-text {
    font-size: 12px;
  }

  .ant-table-wrapper .ant-table.ant-table-small::after {
    padding: 20px 7px;
  }

  .table_scheme_wrap>div.ant-divider {
    margin: 0;
  }

  .All_scheme_status,
  .collection_header,
  .ant-space-align-center,
  .cards_container,
  .create_scheme_wrap .draft-scheme-header {
    flex-wrap: wrap;
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    justify-content: center;
  }

  .amgr_year {
    padding: 10px;
  }

  .nav_wrap {
    width: 100%;
    height: auto;
    padding: 0;
  }

  .ant-card {
    width: 100%;
    margin: 0 0 16px 0;
  }

  .create_scheme_wrap {
    padding: 15px 23px !important;
    margin: 0;
  }

  .ant-table-wrapper .ant-table.ant-table-small::after {
    position: unset;
  }

  .navigation {
    padding-top: 0;
  }

  .logo_sec {
    padding-top: 20px;
  }
}

.create_scheme_wrap h2 {
  font-weight: 700;
  font-size: 25px !important;
  line-height: 37px;
  padding: 8px 0 !important;
}

@media(min-width:1025px) and (max-width:1800px) {
  /* div#root .ant-row .ant-col.ant-col-20 {
    margin-right: 2%;
    margin-left: 5%;
    flex: 0 0 92.333333% !important;
    max-width: 92.333333% !important;
} */

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed+.ant-row .ant-col.ant-col-20 {
    max-width: 89.333333% !important;
  }

  .dash_card_left .dash_card_left_title {
    padding-right: 0;
  }

  .nav_wrap {
    width: 203px;
  }
}

.ant-layout {
  background: none
}

.ant-layout .ant-layout-sider {
  background: #236138;
  position: fixed;
  /* min-width: 250px !important; */
  padding: 0 28px;
  min-width: 285px !important;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
  min-width: 110px !important;
}

aside[style] {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
  height: 100%;
  z-index: 50;
}

@media(min-width:1025px) {
  .dash_card {
    padding: 16px 16px;
  }

  .ant-row .head_Wrapper {
    padding: 15px 0;
    position: sticky;
    top: 0;

    background-color: #ebf4ee;
  }

  .create_scheme_wrap {
    padding: 10px 10px !important;
    margin-top: 30px;
    border-radius: 10px;

  }

  .All_scheme_status {
    flex-wrap: wrap;
    margin-right: 0 !important;
  }
}


@media(min-width:1025px) and (max-width:1300px) {
  .dash_card_left .dash_card_left_state {
    padding-bottom: 12px;
  }
}

@media(max-width:414px) {

  .ant-row .head_Wrapper {
    padding: 25px;
  }

  .ant-row .head_title span {
    font-size: 35px;
  }

  .amgr_year {
    padding: 30px 60px 0 30px;
    flex-wrap: wrap;
  }

  .nameTagWrap .nameHead {
    white-space: nowrap;
  }

}

@media(max-width:1024px) {
  .ant-row .head_Wrapper {
    padding: 25px 15px;
  }

  form.ant-form.ant-form-horizontal .ant-table-wrapper {
    padding-right: 0 !important;
  }
}

.ant-layout main.ant-layout-content {
  background: transparent !important;
}

.ant-layout .ant-layout-sider-children li.ant-menu-submenu.ant-menu-submenu-vertical {
  margin: 30px 0;
}

@media(max-width:767px) {
  .ant-layout .ant-layout-sider {
    padding: 0 7px;
  }

  .ant-btn.ant-btn-icon-only {
    width: auto;
  }

  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed span.anticon.anticon-left-circle {
    margin-left: 49px !important;
  }

  aside.ant-layout-sider.ant-layout-sider-dark .ant-layout-sider-children .logo_sec button .anticon.anticon-right-circle {
    margin-left: 62px;
  }

  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    min-width: 35px !important;
  }

  .ant-layout .ant-layout-sider-children li.ant-menu-submenu.ant-menu-submenu-vertical .ant-menu-submenu-title {
    margin: 0;
  }

  .ant-layout .ant-layout-sider-children li.ant-menu-submenu.ant-menu-submenu-vertical {
    margin: 14px 0;
  }

  .ant-menu-light .ant-menu-item-selected {
    padding: 0 17px;
    margin-top: 16px;
  }

  .ant-collapse>.ant-collapse-item {
    padding: 0 !important;
  }
}

.ant-dropdown-placement-bottomLeft ul li span a {
  text-transform: capitalize;
}

@media(min-width:1300px) and (max-width:1400px) {
  div#root .ant-row .ant-col.ant-col-20 {
    margin-right: 2%;
    margin-left: 5%;

  }
}

@media(min-width:1100px) and (max-width:1300px) {
  div#root .ant-row .ant-col-20 {
    flex: 0 0 90.333333%;
    max-width: 90.333333%;
  }

  #root>div>.ant-col-20 .ant-layout main.ant-layout-content {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }
}

.logo_sec button.ant-btn {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-left: 3rem;
}

.logo_sec .ant-btn-text:not(:disabled):hover {
  background: transparent;
}

.logo_sec .ant-btn-text.ant-btn-icon-only.expand svg,
.logo_sec .ant-btn-text.ant-btn-icon-only.expand {
  margin-left: 0 !important;
}

.logo_sec .ant-btn-text.ant-btn-icon-only.expand {
  display: unset;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed li.ant-menu-item.ant-menu-item-selected {
  height: auto;
}

.ant-table-title {
  border: 1px solid transparent !important;
  padding: 0 !important;
}

.table_scheme_wrap .ant-row h2.unconditional {
  font-size: 20px !important;
}

.ant-modal-header .ant-modal-title {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #464646;
  margin-bottom: 15px;
}

ant-btn-text:not(:disabled):hover {
  color: rgba(0, 0, 0, 0.88);
  background-color: transparent;
}

.create_scheme_wrap.approval-schme-row-cdo {
  padding: 0 !important;
  margin: 0 !important;
  border-top: 1px solid #E4E4E4;
  border-radius: 0;
}

.create_scheme_wrap.approval-schme-row-cdo .table_scheme_wrap.edit-approval-scheme-page {
  padding-top: 53px;
}

.create_scheme_wrap.approval-schme-row-cdo .table_scheme_wrap.edit-approval-scheme-page h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px;
  color: #333333;
  padding: 0 !important;
}

/* .table_scheme_wrap div:last-child {
  padding: 0 !important;
} */
.dashboard-collapse .ant-row .ant-col {
  padding: 0 !important;
}

.titanic .ant-table-title {
  display: none;
}

.ant-table-container {
  border-radius: 0px !important;
}

.ant-table-container {
  border-inline-start: 1px solid transparent !important;
  border-top: 1px solid transparent !important;
}

.heightauto .ant-select-selector {
  height: 42px !important;
  padding: 7px 10px !important;
  margin-right: 12PX;
}

.marginauto {
  margin-right: 12px;
  display: inline-block;
}

div#space {
  width: 100%;
  margin-bottom: 20px;
  justify-content: left;
}

div#space span.ant-select-arrow {
  right: 20px !important;

}

.navigation {
  border: none;
  background: #2a7342;
  color: white;
  padding-top: 40px;
  margin-top: 20px;
  overflow-y: auto;
  max-height: 60vh;
}