.create_scheme_wrap{
  padding: 0 60px 0 60px;
}

.create_scheme_wrap .draft-scheme-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
 padding-top: 0;
  padding-bottom:20px;
}


.create_scheme_wrap .create-btn {
  background-color: yellow;
}

.draft-scheme-pagination {
  text-align: center;
  padding: 8px 0px;
}
thead.ant-table-thead {
  text-align-last: center;
}



