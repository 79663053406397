
    .trackcollection {
        .ant-row .ant-space-item .ant-btn{
        padding: 8px 20px !important;
        border-radius:none !important
    }

}

.create_scheme_wrap{
    padding: 10px 10px !important;
}
div#table {
    margin-top: 0;
}
ul.ant-pagination {
    margin-bottom: 0 !important;
}
.jodit-workplace+.jodit-status-bar:not(:empty) {
    border-top: 1px solid #dadada;
    display: none;
}
.jodit-ui-group.jodit-ui-group_line_true.jodit-ui-group_size_middle{
    display: none;
}