.cards_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 18px 15px 32px 15px;
    background: #FFFFFF;
    border-radius: 0px 0px 10px 10px;

}

.amgr_year {
    padding: 30px 0px 10px 35px;
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    border-radius: 10px 10px 0 0;
}

div#root {
    background: #ebf4ee;
    min-height: 100vh;

}

.table_scheme_wrap>div.ant-divider {
    display: none;
}

#root>div>.ant-col-4 {
    max-width: 100px;
}

#root>div>.ant-col-20 .ant-layout main.ant-layout-content {
    padding: 0 !important;
    position: relative;
    /* max-width: 1780px; */
}

.pmis_wrapper .table_scheme_wrap h2 {
    padding-left: 0 !important;
}

.rc-virtual-list-holder-inner .ant-select-item-option-content a {
    color: #333;
}

.ant-spin-container tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0.editable-row td.ant-table-cell:last-child .ant-space-item a {
    text-transform: uppercase;
    font-weight: 600;
}

.ant-spin-container tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0.editable-row td.ant-table-cell:last-child .ant-space {
    justify-content: start;
}

td.ant-table-cell.ant-table-cell-fix-left,
td.ant-table-cell {
    /* border: 1px solid #D8D8D8; */
    padding: 5px 0 !important;
    text-align: left;
}

#space .ant-btn.css-dev-only-do-not-override-yp8pcc.ant-btn-default {
    height: 42px !important;
    border-radius: 0px !important;
    margin-right: 12px;
}

button#downloadbtn {
    position: absolute;
    right: 485px;
    top: 10px;
}

div#table {
    position: relative;
}