.create_scheme_wrap {
  padding: 40px 60px 0 60px !important;
}
.ant-btn .ant-btn-text > button {
  gap: 100px !important;
}
.table_sub_buttons .ant-btn {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0 !important;
}
.hidden_row {
  visibility: collapse;
}
.ant-table-wrapper .ant-table.ant-table-middle::after {
  content: "B = Billing I = Installation  C = Collection";
  position: absolute;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  color: #464646;
  padding: 16px 0px;
}
.ant-table-wrapper .ant-table.ant-table-middle {
  font-size: 12px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fce838 !important;
  border-color: #fce838 !important;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
  /* background-color: #FCE838 !important; */
  border-color: 0px solid !important;
}
.ant-pagination
  .ant-pagination-item-active
  a {
  color: black !important;
  background-color: #fce838 !important;
  border-radius: 100px !important;
  border: 0px !important;
}
.ant-pagination .ant-pagination-item-active {
  border: 0px !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  min-width: 33px !important;
  height: 0px !important;
  line-height: 30px !important ;
  margin: 0 3px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 0 !important;
}
.saveasdrft svg>path {
  color: #fff;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ant-collapse>.ant-collapse-item:last-child, 
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 8px;
  padding: 5px 10px!important;
}
.create_scheme_wrap.collection-schme-box .table_scheme_wrap .ant-divider {
  display: block;
}
.create_scheme_wrap.collection-schme-box form .ant-table-title {
  padding: 0 !important;
}
.ant-collapse-item.ant-collapse-item-active.panel_heading .ant-collapse-expand-icon svg {
  transform: rotate(267deg);
}
.ant-collapse-item.ant-collapse-item-active.panel_heading .ant-collapse-expand-icon svg {
  transform: rotate(267deg) !important;
}
.ant-table-wrapper .ant-table.ant-table-middle {
  padding: 25px 22px;
}
thead.ant-table-thead {
  text-align-last: center;
}


.error-list-p {
  padding-left: 60px;
}

.error-list-p ul {
  width: fit-content;
}
.error-list-p ul li {
  text-align: left;
}
.Button.createscheme .ant-col:nth-child(2) {
  width: 20%;
}

.Button.createscheme .ant-col:nth-child(3) {
  width: 18.5%;
}

.Button.createscheme .ant-col:nth-child(4) {
  width: 18.5%;
}

.Button.createscheme .ant-col:nth-child(5) {
  width: 18%;
}

.Button.createscheme .ant-col:nth-child(1) {
  width: 20%;
}
.Button.createscheme {
  width: 100%;
}

.Button.createscheme .ant-col .ant-space-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 38px;
}

.Button.createscheme .button_inner_sec Button {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px !important;
}

.Button.createscheme .button_inner_sec Button .ant-space.ant-space-gap-row-small.ant-space-gap-col-small {
  column-gap: 0px;
  row-gap: 0;
}