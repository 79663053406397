.create_scheme_wrap{
  padding: 40px 60px 0 60px !important;
};
.ant-btn  .ant-btn-text >button{
gap: 100px !important;
}
.table_sub_buttons .ant-btn {
display: flex;
gap: 5px;
align-items: center;
}
.ant-table-wrapper .ant-table.ant-table-middle::after {
content: "B = Billing I = Installation  C = Collection";
position: absolute;
display: flex;
font-size: 13px;
font-weight: 500;
color:#464646;
padding: 16px 0px;
}
.anticon-save>svg>path {
  color: #fff;
}
.ant-table-wrapper .ant-table.ant-table-middle {
font-size: 12px !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
background-color: #FCE838 !important;
border-color: #FCE838 !important;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
/* background-color: #FCE838 !important; */
border-color: 0px solid !important;
}
.ant-pagination .ant-pagination-item-active a {
color: black !important;
background-color: #FCE838 !important;
border-radius: 100px !important;
border: 0px !important;
}
.ant-pagination .ant-pagination-item-active {
border: 0px !important;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item {
min-width: 33px !important;
height: 0px !important;
line-height: 30px !important ;
}
.editable-cell {
position: relative;
}

.editable-cell-value-wrap {
padding: 5px 12px;
cursor: pointer;
border: none !important;
}

.editable-row:hover .editable-cell-value-wrap {
padding: 4px 11px;
border: 1px solid #d9d9d9;
border-radius: 2px;
}
thead.ant-table-thead {
  text-align-last: center !important;
}
