.yearPicker{
    border-radius: 0px !important;
    font-size: 16px;
    width: 170px !important;
    height: 42px;
    padding: 0px 10px 0px 10px;
    color: black;
}
.pmis_wrapper .main-div-select.amgr_year .ant-select-selector {
    height: 42px !important;
    justify-content: center;
    align-items: center;
}