.modal_wrap{
    display: flex;
    flex-direction: column;
    cursor: default;
}
.modal_req_head{
    text-align: center;
}
.modal_req_head h1{
    font-weight: 600;
    font-size: 30px;
}
.modal_req_head h4{
    font-weight: 500;
    font-size: 16px;
}
.two_status{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}
.two_status span{
    width: 150px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 58px;
    padding: 30px;
    font-size: 16px;
    color: #464646;
    font-weight: 400;
}
.modal_textarea{
    margin-top: 20px;
}
.dev_btn{
    background: #464646;
    width: 141px;
    font-weight: 700;
    font-size: 14px;
}

.modal_req_head.requst-dev-heading h1 {
    margin: 0 0 16px;
}
.modal_req_head.requst-dev-heading h4 {
    padding: 0 0 40px 0;
}
.modal_wrap.request-dev-popup .modal_textarea textarea {
    background: #F7F7F7;
    border: 1px solid #F7F7F7;
    border-radius: 0;
}
.modal_wrap.request-dev-popup .modal_textarea textarea::placeholder {
    color: #464646;
    padding: 20px;
    font-weight: 400;
font-size: 14px;
line-height: 17px;
text-transform: capitalize;
}
.modal_wrap.request-dev-popup .MuiBox-root input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #464646;
}
/* .modal_wrap.request-dev-popup .MuiBox-root .Mui-disabled {
    -webkit-text-fill-color: #464646;
} */
.modal_wrap.request-dev-popup .MuiBox-root .MuiFormControl-root {
    margin: 15px 15px 0 0;
    border-radius: 0;
}
.create_scheme_wrap.general-bscheme .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
}
.create_scheme_wrap.general-bscheme .ant-select.select_state {
    float: right;
}
thead.ant-table-thead span.anticon.anticon-filter {
    background: #fff;
    border-radius: 50%;
    padding: 7px 7px;
}