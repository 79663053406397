.pmis_wrapper {
    display: flex;
    flex-direction: column;
}

.pmis_wrapper .table_scheme_wrap {
    padding: 20px 0;
}

.ant-col-20 {
    display: block;
    flex: 0 0 79.33333333333334%;
    max-width: 79.333333%;
}

.pmis_wrapper .ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(2) td.ant-table-cell:last-child,
.pmis_wrapper .ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(3) td.ant-table-cell:last-child {
    font-weight: 500 !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    background: #fff !important;
}

.scheme-switch-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;

}

thead.ant-table-thead {
    text-align-last: center;
}

.scheme-switch-row .ant-switch.ant-switch-checked {
    background: red;
}

.scheme-switch-row .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: red;
}

.scheme-switch-row .ant-switch {
    background: #236138;
    color: "white !important"
}

.scheme-switch-row .ant-switch:hover:not(.ant-switch-disabled) {
    background: #236138;
}

.scheme-switch-row .ant-switch:hover:not(.ant-switch-disabled).ant-switch-inner.ant-switch-inner-unchecked {
    color: "white"
}

.scheme-switch-row .ant-switch .ant-switch-inner .ant-switch-inner-unchecked. {
    color: #2b2828;
}

.pmisTable {}