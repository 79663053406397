.All_scheme_status{
    display:flex;
    justify-content:flex-start;
    margin-top: 3rem;
    justify-content: flex-start !important;
    gap:10px;
}
.Schemes_status{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    color: #464646;
}
.Status_color{
    width: 13px !important;
    height: 13px;
    border-radius: 50%;
}
.Status_colors{
    height:20;
    width:20%;
    padding-bottom:20%;
  
}

.btn-big {
    border-radius: 0 !important;
    width: 207px !important;
    height: 42px !important;
    background-color: #236138 !important;
    font-size: 13px !important;
    font-weight: 600;
    color:#fff !important;
    padding: 8px 8px !important;
    text-transform: uppercase !important;
    border-color: transparent;

}
.All_scheme_status{
    padding: 0 1px 0 0;
}
.status-btn-col-box div .ant-space .ant-space-item .yearPicker.status-btn-box {
    background: #464646;
    border-radius: 0px;
    height: 38px;
    color: #fff !important;
    /* margin-top: 2rem; */
    text-transform: capitalize;
}
.status-btn-col-box div .ant-space .ant-space-item .yearPicker.status-btn-box span, .status-btn-col-box div .ant-space .ant-space-item .yearPicker.status-btn-box span svg {
    color: #fff;
}
.status-btn-col-box div {
    display: flex;
    align-items: flex-start;
}
span.ant-dropdown-menu-title-content .ant-btn-link.link {
    padding: 0;
}

.logo_sec svg {
    border-radius: 7px;
}


.dashboard-collapse .zonalhead div:first-child .status-btn-col-box {
    justify-content: center;
    align-items: center;
}
.create_scheme_wrap.general-bscheme {
    padding: 44px 59px 70px 59px !important;
    margin-top: 0.5rem !important;
    background: #fff;
}
.create_scheme_wrap.general-bscheme .ant-select-selector {
    min-height: 47px !important;
    max-height: 67px !important;
}
.create_scheme_wrap.general-bscheme .ant-table-title {
    display: none;
}
.table_scheme_wrap.onemoth-date-selector {
    padding: 30px 30px 30px 30px !important;
    margin-top: 0.5rem !important;
    background: #fff !important;
}
.table_scheme_wrap.onemoth-date-selector h2 {
    font-weight: 700;
    font-size: 30px !important;
    line-height: 37px;
    color: #333333;
    padding: 0 !important;
}
.pmis_wrapper .main-div-select.amgr_year {
    padding: 0 !important;
}
.ant-layout-sider-children ul.ant-menu {
    border-inline-end: 1px solid transparent !important;
}
.dashboard-collapse .create_scheme_wrap.onemoth-date-selector {
    padding: 10px 10px !important;
    background: #fff;
    margin-top: 3rem;
}
.create_scheme_wrap.onemoth-date-selector .ant-row {
    justify-content: space-between !important;
}
.create_scheme_wrap.collection-schme-box.creating-schema-box {
    padding: 0 !important;
}
.approved-icon svg {
    color: #51de4e;
}
.reject-icon svg{
    color: #ff3232;
}
#root>div>.ant-col-20 .ant-layout main.ant-layout-content .view-schela .ant-row {
    justify-content: end !important;
}
