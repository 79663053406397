.Scheme_Status_Heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top:  20px;
    margin-bottom: 15px;
}
.scheme_status{
    margin-top: 35px;
}

.left-arrow-icon2{
    margin: 0px 0 0 15px;
    font-size: 18px;
    font-weight: 700;
}
.arrow_heading{
    display: flex;
    align-items: center;
    cursor: default;
}
thead.ant-table-thead {
    text-align-last: center;
  }