
.SummaryModal .ant-modal-title {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #464646;
  margin-bottom: 39px;
  }

 .SummaryModal .ant-modal-content {
  border-radius: 20px;
  padding: 63px;
}
.SummaryModal button.ant-btn {
  height: 53px !important;
  height: auto;
  text-transform: capitalize !important;
}
.SummaryModal .ant-modal-body .summary_card,
.SummaryModal .ant-modal-body .summary_card .ant-row {
    display: block;
}
.SummaryModal .ant-modal-body button.ant-btn{
  width: 100%;
}
.SummaryModal .ant-modal-body button.ant-btn {
    display: block;
    width: 100% !important;
    margin: 15px 0;
    border: 1px solid transparent;
}
.summary_card .ant-row.css-dev-only-do-not-override-htwhyh .ant-col.css-dev-only-do-not-override-htwhyh button.ant-btn {
  width: 100% !important;
  padding: 0px 10px !important;
}
@media only screen and (max-width: 530px) {
  .summary_card .ant-row.css-dev-only-do-not-override-htwhyh .ant-col.css-dev-only-do-not-override-htwhyh button.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}
}
  .custom-tabs .ant-tabs-ink-bar {
    background: #fce838 !important;
    width: 200px !important;
  }
  .ant-tabs-nav-wrap .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0px 0 58px;
}
.ant-tabs-nav-list .ant-tabs-tab {
  line-height: 18px;
  text-align: center;
  color: #737373;
  font-weight: 500;
}
  .custom-tabs .ant-tabs-tab-btn {
    color: #464646 !important;
  }
  .custom-tabs .ant-tabs-tabpane {
    text-align: center !important;
  }
  /* cancle botton */
  .SummaryModal .ant-modal-footer  {
    text-align: center;
}
.SummaryModal button.ant-btn {
  background-color: #464646;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0;
  height: 53px !important;
  margin: 15px 10px;
}
@media only screen and (max-width: 767px) {
  .SummaryModal button.ant-btn {
  width:100%;
  }
.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 42px 0 !important;
  }
}

.ant-modal .ant-modal-content{
  border-radius: 0;
  padding: 40px;
  text-align: center;
}
.ant-modal .ant-modal-content .ant-modal-footer {
  text-align: center;
}
.textLeft{
  text-align: left;
}
.textRight{
  text-align: right;
}
.link,.link:hover{
  color: black;
  font-weight: 500px ;
}
.summary_card{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.summary_card button{
  width: 100%;
  background: #F7F7F7 !important;
    /* border-radius: 58px !important; */
    color: #464646 !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 20px !important;
}

.ant-table-header table .ant-table-thead tr:first-child th.ant-table-cell:nth-child(15), .ant-table-header table .ant-table-thead tr:first-child th.ant-table-cell:nth-child(16), .ant-table-header table .ant-table-thead tr:first-child th.ant-table-cell:nth-child(13), .ant-table-header table .ant-table-thead tr:first-child th.ant-table-cell:nth-child(10), .ant-table-header table .ant-table-thead tr:first-child th.ant-table-cell:nth-child(20), .ant-table-header table .ant-table-thead tr:first-child th.ant-table-cell:nth-child(12), .ant-collapse .ant-collapse-content>.ant-collapse-content-box thead.ant-table-thead tr:first-child th:nth-child(14)   {
  /* background: #FFFDF4 !important; */
  font-size: 13px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  /* color: #BCB38D !important; */
  text-align: center !important;
  font-weight: 600 !important;
}
.SummaryModal .ant-modal-body button.ant-btn .ant-space {
  display: flex;
  justify-content: space-between;
  padding: 0 22px 0 0;
}
.summary_card .anticon svg {
  color: #D8D8D8;
  width: 12px;
  height: 12px;
}
@media(max-width:1024px){
  .SummaryModal .ant-modal-content {
    padding: 53px 25px;
}
.SummaryModal .ant-modal-title {
  font-size: 26px;
}
}
@media(min-width:769px){
  .SummaryModal button.ant-btn {
    width: 138px !important;
  }
}


.amgr_year .ant-select.select_state span.ant-select-arrow svg {
  color: #D9D1B1;
  width: 10px;
  height: 10px;
}
.ant-pagination .ant-pagination-disabled .ant-pagination-item-link, .ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: #fff;
}
.pmis_wrapper .twice-box-table .ant-picker.start {
  display: none;
}


:where(.css-dev-only-do-not-override-yp8pcc).ant-picker .ant-picker-input >input::placeholder{
  color: #464646;
  font-weight: 500;
}
:where(.css-dev-only-do-not-override-yp8pcc).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #464646;
  font-weight: 500;
}

/* :where(.css-dev-only-do-not-override-yp8pcc)[class^="ant-table"] [class^="ant-table"]{
  text-align: center;
} */
.ant-collapse-extra {

  height: 45px;
  width: 45px;
  border-radius: 50px;
  background: #F7F7F7;
  margin-bottom: 6px;
  text-align: center;
  padding-top: 3px;
}
