.ant-card .ant-card-body {
  padding: 0px !important;
  border-radius: 0 0 8px 8px !important;
  border: 1px solid transparent;
}
.ant-card{
  width: 31%;
  border: 1px solid transparent;
  margin: 0 13px;
}
.dash_card {
  display: flex;
  align-items: center;
  padding: 20px 18px;
  justify-content: space-between;
  /* background: #F9F9F9; */
  border-radius: 10px;
}

.dash_card .dash_card_left {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat" !important;
}

.dash_card_left .dash_card_left_title {
  font-weight: 800;
  font-size: 45px;
  line-height: 91px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  margin: 0;
  padding-right: 15px;
}

.dash_card_left .dash_card_left_state {
  flex-wrap: nowrap;
  width: 93px;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  /* color: #464646 !important; */

}
.cards_container .dash_card .dash_card_button {
  border-radius: 0px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  /* padding: 12px 25px !important; */
  line-height: 15px;
  height: 40px !important;
  width: max(140px,7vw);
  margin-left: 10px;
  background-color: #236138;
  color: #fff;
  border-color: #236138;
}
.ant-pagination.ant-pagination-mini .ant-pagination-item:not(.ant-pagination-item-active),
.ant-pagination .ant-pagination-item{
  border: none;
}



.ant-table-tbody tr td {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #464646;
}
div#root .ant-row .ant-col-20 {
  flex: 0 0 92.333333%;
  max-width: 92.333333%;
}
