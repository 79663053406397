.ChangeSchemeModal .ant-modal-content {
  border-radius: 20px;
  padding: 63px;

}

.ChangeSchemeModal .ant-btn-default {
  background-color: #464646;
  width: 140px;
  color: #C7C7C7;
  text-transform: uppercase;
  font-weight: 400;
  border-radius: 0;
  height: 53px !important;
  margin: 15px 0px;
  border: 0;
}

.WarningIcon {
  right: 0.02%;
  top: 14.17%;
  bottom: 8.33%;
  margin-bottom: 25px;
}

.WarningText {
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
  text-align: center;

  margin-bottom: 7px !important;
  width: 190px;
  margin: auto;
  border-radius: 5px;
  padding: 10px 0px;
  /* box-shadow: 0 0 13px #ccc; */
}

.Text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.ChangeSchemeModal .ant-modal-footer {
  text-align: center;
  margin-top: 20px;
}

.ChangeSchemeText {
  text-align: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
}

.ChangeSchemeTextLine {
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}

.ChangeSchemeButton {
  width: 100% !important;
  height: 50px !important;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 35px;
  background: #F7F7F7 !important;
}

.yearly-basis-box {
  text-align: left;
  padding: 4px 37px 4px;
}

.ChangeSchemeModal .ant-modal-body .yearly-basis-box span {
  color: #C7C7C7;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
}

.ChangeSchemeDate {
  border-radius: 35px;
  display: flex;
}

.ChangeSchemeDate .startDate {
  border-right: 1px solid #fff;
  border-radius: 0;
}

.startDate {
  border-radius: 0px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 50px;
  padding: 4px 37px 4px;
  border: 0;
  background: #F7F7F7
}

.startDate .ant-picker-clear,
.endDate .ant-picker-clear {
  background: transparent;
  right: -23px;
}

.endDate {
  border: 0;
  border-radius: 0px !important;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 50px;
  padding: 4px 37px 4px;
  background: #F7F7F7
}

.ChangeSchemeModal .ant-btn-default span {
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

button.ant-btn.ant-btn-primary:hover {
  background: #fce838;
  color: black;
}

@media(max-width:767px) {
  .ChangeSchemeModal .ant-modal-content {
    padding: 24px 19px;
  }

  .yearly-basis-box {
    width: 100% !important;
  }

  .ant-table-wrapper .ant-table.ant-table-middle::after {
    position: unset !important;
  }
}

.ant-collapse .allSchmeStatus>div>div button {
  background: #464646;

}

.ant-collapse .allSchmeStatus>div>div button span,
.ant-collapse .allSchmeStatus>div>div button svg {
  color: #fff !important;
}