
.modificationcollection {
    .ant-row .ant-space-item .ant-btn{
    padding: 8px 20px !important;
    border-radius:none !important
}

}
.total-row {
    background-color: #c91f1f; /* Change this to the desired background color */
    font-weight: bold; /* Optionally, make the text bold */
}

.status{
    margin-right: 20px;
  }

  .button.SchemeStatus{
    width: 120px;
  }