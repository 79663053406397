/* .create_scheme_wrap, .create_scheme_wrap.zonalhead{
    background: #fff;
    margin: 30px 0 0 0;
    padding: 50px 56px 32px 57px !important;
    border-radius: 10px;
    margin-bottom: 30px;
} */

.create_scheme_wrap .recent-boxes h2 {
    font-weight: 700;
    font-size: max(26px, 1.5vw) !important;
    line-height: 37px;
    padding: 0 0 20px 0 !important;
    padding-left: 0 !important;
    width: 100%;

}

.ant-table-wrapper .ant-table .ant-table-header th[scope='colgroup'],
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(2) th[scope='col'],
.ant-table-wrapper .ant-table .ant-table-header th[scope='colgroup'],
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(9),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(11),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(19),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(22),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(14),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(18),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(17),
.ant-table-wrapper .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table .ant-table-thead tr:nth-child(1) th[scope='col']:nth-child(21),
.ant-table-wrapper .ant-table-thead>tr>th[colspan]:not([colspan='1']),
.ant-table-wrapper .ant-table-thead>tr>td[colspan]:not([colspan='1']),
th.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-container .ant-table-header thead.ant-table-thead tr:nth-child(1) th.ant-table-cell:nth-child(25) {
    /* background: #FFFDF4; */
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    /* color: #BCB38D; */
    text-align: left !important;
    font-weight: 600;
    right: 0px !important;

}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected>td {
    background: #fff;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover>td.ant-table-cell-row-hover {
    background: #fff;
}

.ant-table-wrapper .ant-table.ant-table-middle .ant-table-filter-trigger svg,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-filter-trigger svg,
.ant-table-wrapper .ant-table-column-sorters svg {
    color: #d8d8d8;
}

.ant-table-wrapper td.ant-table-column-sort {
    background: #ffff;
}

.ant-table-wrapper .ant-table-filter-trigger:hover {
    background: transparent;
}

.ant-table-wrapper .ant-table {
    background: transparent;
}

.ant-table-wrapper .ant-table-thead>tr>th:nth-child(1) {
    text-align: center !important;
}

.ant-table-wrapper table thead.ant-table-thead tr th {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
    text-align: center !important;

}

/* .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: -37px 0 10px;
} */

.ant-table-wrapper .ant-table.ant-table-small {
    font-size: 12px !important;
}

.ant-table-wrapper .ant-table.ant-table-small::after {
    content: "B = Billing  I = Installation  C = Collection";
    position: absolute;
    display: flex;
    gap: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #464646;
    padding: 20px 0px 0 0;
    padding: 32px 0px 20px 0px;
    line-height: 16px;
    position: unset;
    float: left;
}

#hold .ant-table-wrapper .ant-table.ant-table-small::after {
    content: unset !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters {
    background: #fafafa;
}

.ant-collapse .ant-collapse-content {
    border-top: 1px solid transparent;
}

.ant-table-wrapper table thead.ant-table-thead tr th {
    font-weight: 700 !important;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: center !important;
    padding: 0 !important;
    color: #464646;
    /* border: 1px solid #D8D8D8; */
    position: relative;
}

.create_scheme_wrap .recent-boxes {
    align-items: start !important;
}

.ant-table-body::-webkit-scrollbar {
    background: #D8D8D8;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background: #464646;
}


.ant-table-body::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

.create_scheme_wrap .recent-boxes p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #464646;
    padding-bottom: 10px;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 0px solid transparent;
    padding: 15px 16px !important;
}

.create_scheme_wrap.zonalhead .ant-table-body td:not(.ant-table-cell-fix-left) {
    text-align: center;
}

.ant-table-wrapper .ant-table .ant-table-container table>thead>tr:first-child th:first-child {
    text-align: left !important;
}

.ant-tag.ant-tag-has-color {
    font-size: 10px !important;
}

.ant-collapse-item.panel_heading .ant-collapse-extra span.anticon svg {
    border-radius: 0;
    padding: 0 !important;
    background: transparent;
    height: 35px !important;
    width: 30px !important;
}

.ant-space .ant-space-horizontal.ant-space-align-center .ant-space-item a {
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
}

.finance .ant-table .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #d9d9d9;
}

/* .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >th, .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
    background: transparent;
} */


tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(22),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(23),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(24),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(25),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(26),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(27),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(28),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(29),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(30),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(31),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(32),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(33),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(34),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(35),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(36),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(37),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(38),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(39),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(40),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(9),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(10),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(11),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(12),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(13),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(14),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(15),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(16),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(17),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(18),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(19),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(20),
tbody.ant-table-tbody tr.ant-table-row.ant-table-row-level-0 td.ant-table-cell:nth-child(21),
.ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(2) td.ant-table-cell:last-child,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(3) td.ant-table-cell:last-child,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(2) td.ant-table-cell:nth-child(41),
.ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(3) td.ant-table-cell:nth-child(41),
.ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(3) td.ant-table-cell:nth-child(42),
.ant-table-wrapper .ant-table.ant-table-small .ant-table-body tr:nth-child(2) td.ant-table-cell:nth-child(42) {
    /* background: #FFFDF4 !important; */
    font-size: 12 px !important;
    line-height: 16px !important;
    text-transform: uppercase !important;
    /* color: #BCB38D !important; */
    text-align: center !important;
    right: 0px !important;
}

.ant-table-container .ant-table-body {
    max-height: 500px !important;
}

@media(min-width:1025px) and (max-width:1100px) {
    .dash_card .dash_card_left {
        flex-direction: column;
    }
}

@media(min-width:1025px)and (max-width:1648px) {
    .ant-card {
        width: auto;
    }
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row-selected:hover>td {
    background: #f0f0f0;
}

/* .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >th, .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
    background: #f0f0f038;
} */
.editable-cell-value-wrap,
td.ant-table-cell.ant-table-cell-row-hover .editable-cell-value-wrap {
    padding: 7px 0 !important;
    /* padding: inherit !important; */
}

.editable-cell-value-wrap,
td.ant-table-cell.ant-table-cell-row-hover .editable-cell-value-wrap {
    height: 40px !important;
    width: 100px !important;
    line-height: 30px;
    margin: 0 auto;
    word-wrap: initial;
}

.table_sub_buttons .ant-btn {
    height: 50px !important;
}

.ant-table-wrapper .ant-table.ant-table-middle {
    padding: 0;
    background: transparent;
}

.ant-table-wrapper .ant-table-pagination {
    align-items: baseline;
}

@media(max-width:767px) {
    .ant-row .collection-schme-box div {
        flex-wrap: wrap !important;
    }

    .All_scheme_status {
        padding: 0;
    }

    .dash_card .dash_card_left {
        gap: 0;
    }

    .ant-row .collection-schme-box {
        padding: 10px !important;
    }

    .demo .action_button {
        margin: 0 0.5rem 1rem;
    }

    .status-btn-col-box {
        display: flex;
    }
}

.status-btn-col-box {
    display: flex;
}

.ant-form-item .ant-form-item-explain-error {
    text-transform: capitalize;
    font-weight: 500;
    text-align: center;


}

.finance .ant-form-item .ant-form-item-explain-error {
    display: none;

}

.ant-table-wrapper .ant-table-summary {
    background: #E4E4E4;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>th,
.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-wrapper .ant-table-tbody>tr>th.ant-table-cell-row-hover>td.ant-table-cell-row-hover {
    background: #fff !important;
}

.ant-table-summary tr td.ant-table-cell.ant-table-cell-fix-left {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #464646;
    border-inline-end: 1px solid transparent !important;
    padding: 10px 0 !important;
}

.ant-table .ant-table-small.ant-table-empty .ant-table-body tr:nth-child(2) td.ant-table-cell {
    background: #fff;
}

.ant-table-wrapper .ant-table-summary td.ant-table-cell {
    border-inline-end: 1px solid transparent !important;
    background: #E4E4E4;
    border: 1px solid transparent;
}

.table_sub_buttons .ant-btn.ant-btn-text>span {
    display: inline-block;
    color: #464646;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
}

.ant-space-horizontal.ant-space-align-center {
    justify-content: center;
}

.ant-space-item .table_sub_buttons .ant-btn-text svg {
    width: 23.57px;
    height: 21.33px;
}

td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

span.ant-dropdown-menu-title-content a.link {
    text-transform: capitalize;
    font-weight: 100;
}

.date .ant-picker {
    border-radius: 0;
    height: 42px;
}

.ant-table-wrapper .ant-table.ant-table-small.ant-table-empty .ant-table-body tr:nth-child(2) td.ant-table-cell:last-child {
    background: #fff !important;
}


.dashboard-collapse .editscheme-page {
    border-top: 1px solid #E4E4E4;
    padding: 20px 0 !important;
    margin: 0;
    border-radius: 0 !important;
}

.editscheme-page h2 {
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    line-height: 24px;
}

.editscheme-page .table_scheme_wrap .ant-col h2 {
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #333333;
    line-height: 24px !important;
}

.ant-spin-container {
    /* border: 1px solid #D8D8D8;
    border-radius: 10px 10px 0px 0px; */
}

.ant-spin-container .ant-row {
    /* padding: 6px 0 !important; */
    border-radius: 0;
}

.pmis_wrapper .main-div-select.amgr_year {
    padding-left: 0;
    background: transparent;
    margin: 0;
}

.collapse-items.approval-scheme .ant-collapse-item {
    padding: 21px !important;
}

.collapse-items.approval-scheme .ant-collapse-item .ant-collapse-header {
    float: left;
}

.dashboard-collapse .zonalhead {
    padding-top: 30px !important;
}

thead.ant-table-thead {
    text-align-last: center;
}